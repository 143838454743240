
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedbackV2,
  CampTable,
  CampTableTd,
  CampTableTh
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

interface ICategoryData {
  id: number,
  name: string,
}

interface ICategoryResponse {
  data: {
    error: boolean,
    message: string,
    data: ICategoryData[]
    errorCode: string
  }
}

export default defineComponent({
  name: "CategoryList",
  components: {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampModalDelRegister
  },
  setup() {
    const router = useRouter();
    const { showTimeAlert } = useAlert()
    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | ICategoryData[]
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    const loaderStore = useLoaderStore()
    const itemDel = ref<ICategoryData | null>(null)
    const toggleModalDelRegister = ref(false)
    const printCategories = ref<null | ICategoryData[]>(null)
    const keywordSearch = ref("")
    const isLoading = ref(false)
    const filterBaseV2Store = useFilterBaseV2Store()

    // Functions
    async function handleGetCategories() {
      isLoading.value = true
      try {
        const company = await filterBaseV2Store.getCompanyInSession()
        if(company === null || !company) {
          isLoading.value = false
          return showTimeAlert("É necessário ter uma companhia setada", 'error')
        }
        const result: ICategoryResponse = await axios.get(`/api/getCategory?id_company=${company.id}`)
        const { data } = result
        queryObject.value.data = data.data
        printCategories.value = data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!queryObject.value.data)
        return true
      printCategories.value = queryObject.value.data.filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    function handleModalDelRegister(item: ICategoryData) {
      toggleModalDelRegister.value = !toggleModalDelRegister.value
      itemDel.value = item
    }

    async function handleConfirmItemDel() {
      if(itemDel.value) {
        try {
          loaderStore.open()
          const result = await axios.delete(`/api/deleteCategory/${itemDel.value.id}`)
          showTimeAlert(`O registro ${itemDel.value.name} foi excluído com sucesso!`)
          handleGetCategories()
          itemDel.value = null
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
        }
      }
    }

    // Life Cycles
    onMounted(() => handleGetCategories())

    watch(() => filterBaseV2Store.checkForUpdates(), () => filterBaseV2Store.checkForUpdates() && handleGetCategories())

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      router,
      keywordSearch,
      printCategories,
      isLoading,
      queryObject,
      handleModalDelRegister,
      toggleModalDelRegister,
      itemDel,
      handleConfirmItemDel
    }
  }
})
